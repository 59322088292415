import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

class Contact extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact" />
        <h1>Contact</h1>
        <h2>Email</h2>
        <p>
          You can email me, if you like! <a href="mailto:andy@andydavison.dev?subject=Hi%20Andy%21">andy@andydavison.dev</a> 
        </p>
        <h2>Twitter</h2>
        <p>You can tweet me up: <a href="https://twitter.com/andydavison">@andydavison</a></p>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
